// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookiePolicy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/FAQ.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-templates-blog-index-blog-index-tsx": () => import("./../../../src/templates/BlogIndex/BlogIndex.tsx" /* webpackChunkName: "component---src-templates-blog-index-blog-index-tsx" */),
  "component---src-templates-blog-post-blog-post-tsx": () => import("./../../../src/templates/BlogPost/BlogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-blog-post-tsx" */),
  "component---src-templates-car-manufacturer-index-car-manufacturer-index-tsx": () => import("./../../../src/templates/CarManufacturerIndex/CarManufacturerIndex.tsx" /* webpackChunkName: "component---src-templates-car-manufacturer-index-car-manufacturer-index-tsx" */),
  "component---src-templates-individual-brands-page-individual-brands-page-tsx": () => import("./../../../src/templates/IndividualBrandsPage/individualBrandsPage.tsx" /* webpackChunkName: "component---src-templates-individual-brands-page-individual-brands-page-tsx" */),
  "component---src-templates-individual-pages-car-brand-tsx": () => import("./../../../src/templates/IndividualPages/carBrand.tsx" /* webpackChunkName: "component---src-templates-individual-pages-car-brand-tsx" */),
  "component---src-templates-individual-pages-car-model-tsx": () => import("./../../../src/templates/IndividualPages/carModel.tsx" /* webpackChunkName: "component---src-templates-individual-pages-car-model-tsx" */),
  "component---src-templates-lego-terms-lego-terms-tsx": () => import("./../../../src/templates/LegoTerms/LegoTerms.tsx" /* webpackChunkName: "component---src-templates-lego-terms-lego-terms-tsx" */),
  "component---src-templates-partners-partners-tsx": () => import("./../../../src/templates/Partners/Partners.tsx" /* webpackChunkName: "component---src-templates-partners-partners-tsx" */),
  "component---src-templates-types-of-tyre-index-types-of-tyre-index-tsx": () => import("./../../../src/templates/TypesOfTyreIndex/TypesOfTyreIndex.tsx" /* webpackChunkName: "component---src-templates-types-of-tyre-index-types-of-tyre-index-tsx" */),
  "component---src-templates-types-of-tyre-page-tyre-types-page-tsx": () => import("./../../../src/templates/TypesOfTyrePage/TyreTypesPage.tsx" /* webpackChunkName: "component---src-templates-types-of-tyre-page-tyre-types-page-tsx" */),
  "component---src-templates-tyre-brands-index-tyre-brands-index-tsx": () => import("./../../../src/templates/TyreBrandsIndex/TyreBrandsIndex.tsx" /* webpackChunkName: "component---src-templates-tyre-brands-index-tyre-brands-index-tsx" */),
  "component---src-templates-tyre-index-tyreindex-tsx": () => import("./../../../src/templates/TyreIndex/Tyreindex.tsx" /* webpackChunkName: "component---src-templates-tyre-index-tyreindex-tsx" */)
}

